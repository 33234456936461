/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import FilesUploaded from "./files-uploaded/files-uploaded";
import QuestionPrompts from "./question-prompts/question-prompts";
import DataSourceFiles from "./teacher/files/dataSourceFiles";
import {
  getFilesList,
  getQuestionPromptsData,
} from "../../apis/recommendation/recommendation";
import Cookies from "js-cookie";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../components/layout/layout";
import SioIcon from "../../components/icons/IconLibrary";
import Button from "../../components/button";
const DataSource = () => {
  const {
    isArchivedDataSources,
    setIsArchivedDataSources,
    setHighlightField,
    isFilesType,
    setIsFilesType,
  } = useContext(DataContext);

  const [dataSource, setDataSource] = useState(null);
  const [questionPrompts, setQuestionPrompts] = useState(null);
  const adminRoles = ["site-admin", "district-admin", "admin", "school-admin"];
  const [isTeacherView, setIsTeacherView] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  const getFileNames = async () => {
    const files = await getFilesList();
    const filteredFileData = files.filter(
      (file) =>
        file.original_file_name.includes(".csv") ||
        file.original_file_name.includes(".json")
    );
    setDataSource(filteredFileData);
  };

  const getQuestionPrompts = async () => {
    const data = await getQuestionPromptsData();
    setQuestionPrompts(data);
  };

  useEffect(() => {
    getFileNames();
    getQuestionPrompts();
    setHighlightField("DataSource");
    setIsArchivedDataSources(() => {
      const value = path.split("/").includes("archived-data-sources")
        ? true
        : false;
      return value;
    });
    setIsFilesType(() => {
      const value = path.split("/").includes("file-types") ? true : false;
      return value;
    });

    const checkRole = () => {
      const userRole = Cookies.get("user_role") || "teacher";
      if (adminRoles.includes(userRole?.toLowerCase())) {
        setIsTeacherView(true);
      } else {
        setIsTeacherView(false);
      }
    };
    checkRole();
  }, []);

  const tabs = [
    {
      name: "Files Uploaded",
    },
    {
      name: "Question Prompts",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const s3KeysData =
    dataSource &&
    dataSource.map((item) => ({
      name: item.s3_key,
      date: item.created_at,
    }));

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-white h-full rounded-lg justify-center">
      <div className="flex flex-col h-full">
        <div className="items-start md:items-center flex shadow-md p-3 !pb-0 md:p-5 relative z-10">
          <div>
            {isArchivedDataSources ? (
              <h1 className="font-normal text-lg md:text-xl text-primary-dark flex gap-3 items-center mb-4">
                <button
                  className="soi-btn soi-btn-fab soi-btn-flat btn-dark size-9"
                  onClick={() => {
                    setIsArchivedDataSources(false);
                    setIsFilesType(false);
                    handleBack();
                  }}>
                  <SioIcon name="leftArrow" size={18} />
                </button>{" "}
                Archived Data Source
              </h1>
            ) : (
              !isFilesType && (
                <h1 className="font-normal text-lg md:text-xl text-primary-dark">
                  Data Source
                </h1>
              )
            )}

            {!isTeacherView && (
              <p className="text-secondary-dark text-xs mt-1 md:mt-0 md:text-sm pb-4">
                You can check/unchek the data sources to get customized
                suggestions.
              </p>
            )}
            {isTeacherView && !isArchivedDataSources && !isFilesType && (
              <div className="sio-tabs -mx-3 mt-3">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`sio-tab-link ${
                      activeTab === tab.name
                        ? "active relative text-primary-dark before:absolute before:h-[3px] before:rounded-full before:bg-primary before:w-14 before:bottom-0"
                        : "text-muted-dark relative"
                    }`}
                    onClick={() => handleTabClick(tab.name)}>
                    {tab.name}
                    {tab.new === true && (
                      <span className="bg-warning size-[6px] absolute rounded-full top-3"></span>
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
          {isTeacherView && !isFilesType && (
            <Link to="file-types" onClick={() => setIsFilesType(true)}>
              <Button
                variant="dark"
                className="sio-btn-link !text-sm md:text-sm p-2 text-primary-dark absolute right-5"
                size={"sm"}>
                File Types
              </Button>
            </Link>
          )}
        </div>
        {<Outlet />}
        {isArchivedDataSources || isFilesType ? null : !isTeacherView ? ( // <Outlet />
          <DataSourceFiles dataSource={dataSource} />
        ) : (
          <>
            {activeTab === "Files Uploaded" && (
              <>
                {(!isArchivedDataSources || !isFilesType) && (
                  <FilesUploaded dataSource={dataSource} />
                )}
              </>
            )}
            {activeTab === "Question Prompts" && (
              <QuestionPrompts
                s3KeysData={s3KeysData}
                questionPrompts={questionPrompts}
                setQuestionPrompts={setQuestionPrompts}
                dataSource={dataSource}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DataSource;
