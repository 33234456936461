import styles from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import FormComponent from "./partials/form";

import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { getUserNames } from "../../../utils";
import { devApis } from "../../../constants/constant";

export default function CustomRecommendation() {
  const [editedRow, setEditedRow] = useState({});
  const tableRef = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [files, setFiles] = useState([]);
  const [parentRecId, setParentRecId] = useState(null);

  // const navigate = useNavigate();

  const BUCKET_NAME = "fileTypeData";

  async function CreateCustomRecommendation(model) {
    try {
      model = JSON.stringify(model);
      const token = await checkAndRefreshToken();
      const getUUID = await getUserNames();
      const uuid = getUUID.data.org_uuid;
      const params = new URLSearchParams();
      params.append("org_uuid", uuid);

      // console.log(model);
      const response = await axios.post(
        `${devApis.GOLANG_BASE_URL}/create_custom_parent_recommendation`,
        model,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      fetchData();
      setFiles(response.data.parent_recommendation);
      // console.log(files);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchData = async () => {
    const url = `${devApis.GOLANG_BASE_URL}/get_custom_parent_recommendations`;

    try {
      const token = await checkAndRefreshToken();
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // console.log(data);
      setFiles(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const [fileNames, setFileNames] = useState([]);

  const getFileName = async () => {
    const token = await checkAndRefreshToken();
    const url = `${devApis.GOLANG_BASE_URL}/api/file_types`;

    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const names = data.file_types.map((item) => item.name);
      setFileNames(names);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchData();
    getFileName();
  }, []);

  const [clickAddBtn, setClickAddBtn] = useState(false);

  async function handleActionClick(action, file) {
    switch (action) {
      case "Add":
        setShowForm(true);
        setClickAddBtn(false);
        break;
      case "Create":
        setShowForm(true);
        setClickAddBtn(true);
        break;
      case "Edit":
        setEditedRow(file);
        setShowForm(true);
        break;
      case "Delete":
        deleteAction(file);
        break;
      default:
        break;
    }
  }

  const deleteAction = async (file) => {
    const confirm = window.confirm("Are you sure you want to Delete?");
    if (confirm) {
      try {
        const token = await checkAndRefreshToken();
        const response = await fetch(
          `${devApis.GOLANG_BASE_URL}/delete_parent_recommendation/${file.ID}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await response.json();
        // console.log(data);
        alert("Successfully Deleted Record");
        setFiles((prevFiles) => prevFiles.filter((f) => f.ID !== file.ID));
      } catch (error) {
        console.error("Error deleting file:", error);
        alert("Failed to delete record. Please try again.");
      }
    }
  };

  const editRecommendation = async (file) => {
    try {
      const token = await checkAndRefreshToken();
      const response = await fetch(
        `${devApis.GOLANG_BASE_URL}/edit_parent_recommendation/${editedRow.ID}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(file),
        }
      );
      await response.json();
      // console.log(updatedFile);
      alert("Successfully Updated Record");
      fetchData();
    } catch (error) {
      console.error("Error updating file:", error);
      alert("Failed to update record. Please try again.");
    }
  };

  const addChildRecommendation = async (model) => {
    model["recommendation_id"] = parentRecId;
    console.log(model);
    try {
      const token = await checkAndRefreshToken();
      const response = await fetch(
        `${devApis.GOLANG_BASE_URL}/create_custom_child_recommendation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(model),
        }
      );
      await response.json();
      alert("Successfully Updated Record");
      fetchData();
    } catch (error) {
      console.error("Error updating file:", error);
      alert("Failed to update record. Please try again.");
    }
  };

  const actionsList = [
    {
      name: "Create",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Plus"><path d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/></svg>`,
    },
    {
      name: "Edit",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Edit"><path d="M16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621z"/><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3"/></svg>`,
    },
    {
      name: "Delete",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-TrashCan"><path d="M4 6h16l-1.58 14.22A2 2 0 0 1 16.432 22H7.568a2 2 0 0 1-1.988-1.78L4 6z"/><path d="M7.345 3.147A2 2 0 0 1 9.154 2h5.692a2 2 0 0 1 1.81 1.147L18 6H6l1.345-2.853z"/><path d="M2 6h20"/><path d="M10 11v5"/><path d="M14 11v5"/></svg>`,
    },
  ];

  console.log(files);

  return (
    <div className={styles.list__wrapper}>
      {files?.length > 0 ? (
        <>
          <div className={styles.header__ele}>
            <span>Add Parent Recommendation</span>
            <button onClick={() => handleActionClick("Add", {})}>Add</button>
          </div>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>File Type</th>
                <th>Category</th>
                <th>Recommendation</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => {
                const isEditingFile =
                  editedRow &&
                  Object.keys(editedRow).length > 0 &&
                  editedRow.id === file.id;
                return (
                  <tr key={index} data-editing={isEditingFile}>
                    <td>{index + 1}</td>
                    <td>{file.Name || "N/A"}</td>
                    <td data-type="filetype">
                      {/* {console.log(file)} */}
                      {Array.isArray(file.file_type) &&
                      file?.file_type &&
                      file.file_type.length > 0
                        ? file.file_type.map((name) => <div>{name.Name}</div>)
                        : "N/A"}
                    </td>
                    <td>{file.Category || "N/A"}</td>
                    <td>{file.Content}</td>
                    <td data-type="actions">
                      <div>
                        {actionsList.map((action) => (
                          <button
                            key={action.name}
                            data-type={action.name}
                            title={action.name}
                            dangerouslySetInnerHTML={{
                              __html: action.icon,
                            }}
                            onClick={() => {
                              handleActionClick(action.name, file);
                              console.log(file.ID);
                              setParentRecId(file.ID);
                            }}
                          ></button>
                        ))}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div className={styles.stateMsg}>
          No data available. Do you want to add Parent Recommendation?{" "}
          <span
            href="#"
            onClick={() => {
              setShowForm(true);
            }}
          >
            Click here
          </span>
        </div>
      )}

      {showForm && (
        <FormComponent
          setState={setShowForm}
          BUCKET_NAME={BUCKET_NAME}
          setFileState={setFiles}
          editedRow={editedRow}
          setEditedRow={setEditedRow}
          onSave={CreateCustomRecommendation}
          onUpdate={editRecommendation}
          onChild={addChildRecommendation}
          fileNames={fileNames}
          clickAddBtn={clickAddBtn}
          setClickAddBtn={setClickAddBtn}
        />
      )}
    </div>
  );
}
