// src/icons/iconRegistry.js
import { GeneralIcons } from './generalIcons';
import { NavigationIcons } from './navigationIcons';
import { AccountIcons } from './accountIcons';
import { FormIcons } from './formIcons';
import { FileTypeIcons } from './fileTypeIcons';

export const IconRegistry = {
  ...GeneralIcons,
  ...NavigationIcons,
  ...AccountIcons,
  ...FormIcons,
  ...FileTypeIcons
};