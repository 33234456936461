/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import { getFilesList } from "../../apis/recommendation/recommendation";
import { useLocation } from "react-router-dom";

export const DataContext = createContext();

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [dataFromSelectRecommendation, setDataFromSelectRecommendation] =
    useState(null);
  const [dataFromSidebar, setDataFromSidebar] = useState(null);
  const [dataFromRecommendation, setDataFromRecommendation] = useState([]);
  const [dataFromBookmark, setDataFromBookmark] = useState([]);
  const [dataFromChildren, setDataFromChildren] = useState(null);
  const [sendUploadedFileData, setSendUploadedFileData] = useState(null);
  const [deleteQuestions, setDeleteQuestions] = useState(false);
  const [selectQuestions, setSelectQuestions] = useState(null);
  const [homePageData, setHomePageData] = useState(null);
  const [highlightField, setHighlightField] = useState(null);
  const [updatedQuestionsData, setUpdatedQuestionsData] = useState(null);
  const [APIData, setAPIData] = useState({ status: false, payload: {} });

  const location = useLocation();
  const path = location.pathname;

  const [isArchivedDataSources, setIsArchivedDataSources] = useState(() => {
    const value = path.split("/").includes("archived-data-sources")
      ? true
      : false;
    return value;
  });
  const [isFilesType, setIsFilesType] = useState(() => {
    const value = path.split("/").includes("file-types") ? true : false;
    return value;
  });

  const handleDataFromSelectRecommendation = (data) => {
    setDataFromSelectRecommendation(data);
  };

  const handleDataFromSidebar = (data) => {
    setDataFromSidebar(data);
  };

  const handleSelectQuestion = (data) => {
    setSelectQuestions(data);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const showSidebar = () => {
    setIsSidebarOpen(true);
  };

  const hideSidebar = () => {
    setIsSidebarOpen(false);
    setDataFromSidebar(null);
  };

  const handleDataFromLanding = (recommendation, bookmark) => {
    setDataFromRecommendation(recommendation);
    setDataFromBookmark(bookmark);
  };

  const handleDeleteQuestion = (data) => {
    setDeleteQuestions(data);
  };

  const handleHomePage = (data) => {
    setHomePageData(data);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      isSidebarOpen,
      dataFromSidebar,
      dataFromRecommendation,
      dataFromBookmark,
      sendUploadedFileData,
      selectQuestions,
    })
  );

  // const [s3Key, setS3Key] = useState([]);
  const [filesListData, setFilesListData] = useState(null);

  const [s3Key, setS3Key] = useState(() => {
    const saved = sessionStorage.getItem("s3_keys");
    return saved ? JSON.parse(saved) : [];
  });

  const getFileData = async () => {
    const files = await getFilesList();
    const filteredFileData = files.filter((file) =>
      [".csv", ".json", ".csv.filepart"].some((ext) =>
        file.s3_key.includes(ext)
      )
    );
    const filteredS3Keys = filteredFileData.map((file) => file.s3_key);

    if (s3Key.length === 0) {
      sessionStorage.setItem("s3_keys", JSON.stringify(filteredS3Keys));
      setS3Key(filteredS3Keys);
    }
    setFilesListData(filteredS3Keys);
  };

  useEffect(() => {
    getFileData();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("s3_keys", JSON.stringify(s3Key));
  }, [s3Key]);

  return (
    <DataContext.Provider
      value={{
        handleDataFromSelectRecommendation,
        handleDataFromSidebar,
        handleDataFromLanding,
        handleDeleteQuestion,
        dataFromSelectRecommendation,
        dataFromSidebar,
        setDataFromChildren,
        setSendUploadedFileData,
        handleHomePage,
        handleSelectQuestion,
        setHighlightField,
        s3Key,
        setS3Key,
        filesListData,
        updatedQuestionsData,
        setUpdatedQuestionsData,
        APIData,
        setAPIData,
        isArchivedDataSources,
        setIsArchivedDataSources,
        isFilesType,
        setIsFilesType,
      }}
    >
      <div className="bg-dark relative flex h-dvh">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          recId={dataFromSelectRecommendation}
          sendDataToParent={handleDataFromSidebar}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          hideSidebar={hideSidebar}
          dataFromChildren={dataFromChildren}
          sendUploadedFileData={sendUploadedFileData}
          deleteQuestions={deleteQuestions}
          selectedQuestions={handleSelectQuestion}
          homePageData={homePageData}
          highlightField={highlightField}
        />

        <div
          className={`flex-1 pb-20 md:pb-3 pt-10 md:pt-3 p-1 md:p-3 ${
            isSidebarOpen ? "lg:pl-0 ml-[0] lg:ml-[19rem]" : ""
          } transition-margin duration-300 h-[100%] w-[1%]`}
        >
          {childrenWithProps}
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default Layout;
