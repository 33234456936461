/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import SavedQuestionsSidebar from "./savedQuestionsSidebar";
import SavedQuestionsContainer from "./savedQuestionsContainer";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import {
  addMessageToSession,
  bookmarkQuestions,
  getSavedQuestions,
} from "../../../apis/recommendation/recommendation";
import { DataContext } from "../../../components/layout/layout";
import { CircularProgress, Drawer, TextField } from "@mui/material";
import { devApis } from "../../../constants/constant";
import Cookies from "js-cookie";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import SioIcon from "../../../components/icons/IconLibrary";

const SavedQuestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredRec, setFilteredRec] = useState([]);
  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);
  const [isPromptEdited, setIsPromptEdited] = useState(false);
  const [editedPromptData, setEditedPromptData] = useState(null);
  const [savedQuestionsData, setSavedQuestionsData] = useState(null);
  const [addingNewQuestion, setAddingNewQuestion] = useState(false);

  const {
    setHighlightField,
    filesListData,
    updatedQuestionsData,
    setUpdatedQuestionsData,
  } = useContext(DataContext);

  const navigate = useNavigate();

  const savedQuestionsAPIFunction = async () => {
    const { saved_messages } = await getSavedQuestions();
    setFilteredRec(saved_messages);
    setSelectedSuggestion(saved_messages[0]);
    setUpdatedQuestionsData(null);
  };

  useEffect(() => {
    savedQuestionsAPIFunction();

    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("History");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    setHighlightField("History");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [updatedQuestionsData]);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    setHighlightField("Ask Anything");
    navigate("/");
  };


  const playGroundTopBar = () => {
    return (
      <div className="flex items-center shadow-md p-3 md:p-5 relative z-10">
        <Button
          outline
          fab
          variant="dark"
          className="me-2 size-8 flex lg:hidden"
          size={"xs"}
          onClick={handleModileSidebarToggle}
        >
          <SioIcon name="filter" size={18} />
        </Button>
        <h1 className="font-normal text-lg md:text-xl text-primary-dark">
          Saved Questions
        </h1>
        <div className="items-center ms-auto flex gap-5">
          <Button
            variant="default"
            className="hidden lg:flex"
            size={"sm"}
            onClick={toggleAddNewQuationDrawer(true)}
          >
            Add New
          </Button>
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}
          >
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  const [openAddNewQuationDrawer, setAddNewQuationDrawerOpen] =
    React.useState(false);
  const toggleAddNewQuationDrawer = (newOpen) => () => {
    setAddNewQuationDrawerOpen(newOpen);
  };

  const [customPrompt, setCustomPrompt] = useState("");

  const handleInput = (event) => {
    const { value } = event.target;
    setCustomPrompt(value);
  };

  const debouncedInputHandler = debounce(handleInput, 500);

  // if (!selectedSuggestion) return;

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className={`h-full w-full flex flex-col overflow-hidden`}>
        {playGroundTopBar()}
        <div className={`flex flex-row overflow-hidden h-full`}>
          <SavedQuestionsSidebar
            recommendationData={filteredRec}
            setSelectedSuggestion={setSelectedSuggestion}
            setIsPromptEdited={setIsPromptEdited}
            selectedSuggestion={selectedSuggestion}
            setEditedPromptData={setEditedPromptData}
            setSavedQuestionsData={setSavedQuestionsData}
            setModileSidebarIsActive={setModileSidebarIsActive}
            isModileSidebarActive={isModileSidebarActive}
          />
          <SavedQuestionsContainer
            recommendationData={selectedSuggestion}
            isPromptEdited={isPromptEdited}
            editedPromptData={editedPromptData}
            savedQuestionsData={savedQuestionsData}
            setSavedQuestionsData={setSavedQuestionsData}
          />
        </div>
      </div>
      <Drawer
        anchor={"right"}
        open={openAddNewQuationDrawer}
        onClose={toggleAddNewQuationDrawer(false)}
      >
        <div className="sio-sidebar-right w-[300px] md:w-[500px]">
          <div className="sio-sidebar-header flex items-center">
            <h2>Add New Question</h2>
            <Button
              className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
              onClick={toggleAddNewQuationDrawer(false)}
            >
              <SioIcon name="closeLight"/>
            </Button>
          </div>

          {addingNewQuestion ? (
            <div
              className="flex flex-col justify-start items-center gap-10 text-md 
            font-semibold w-full h-full pt-20"
            >
              <svg width={0} height={0}>
                <defs>
                  <linearGradient
                    id="my_gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress
                sx={{
                  "svg circle": { stroke: "url(#my_gradient)" },
                  size: "20px",
                }}
              />
              <h3>Please wait while we adding the question.</h3>
            </div>
          ) : (
            <>
              <div className="sio-sidebar-body h-auto p-6 !py-7">
                <div className="grid grid-cols-1 gap-y-6">
                  <TextField
                    label="Question"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={debouncedInputHandler}
                  />
                </div>
              </div>

              <div className="sio-sidebar-footer gap-4 flex pt-0">
                <Button
                  variant="secondary"
                  size={"sm"}
                  onClick={async () => {
                    if (customPrompt) {
                      setAddingNewQuestion(true);
                      const promptResponse = await fetch(
                        `${devApis.PYTHON_BASE_URL}/start_conversation`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("token")}`,
                          },
                          body: JSON.stringify({
                            recommendation_id: 0,
                          }),
                        }
                      );

                      const data = await promptResponse.json();
                      const newSessionId = data.session_id;

                      const token = await checkAndRefreshToken();
                      const response = await addMessageToSession(
                        newSessionId,
                        customPrompt,
                        token,
                        filesListData
                      );

                      await bookmarkQuestions(response?.message_id);
                      savedQuestionsAPIFunction();
                      setAddingNewQuestion(false);
                      setAddNewQuationDrawerOpen(false);
                      setUpdatedQuestionsData(true);
                      toast.success("Question added successfully", {
                        icon: (
                          <SioIcon name="check" size={16} color="blue" />
                        ),
                      });
                    } else {
                      toast("Please provide the custom question", {
                        icon: (
                          <SioIcon name="close" size={16} color="blue" />
                        ),
                      });
                    }
                  }}
                >
                  {" "}
                  Save Question{" "}
                </Button>
                <Button
                  variant="default"
                  onClick={toggleAddNewQuationDrawer(false)}
                  size={"sm"}
                  className="text-black"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SavedQuestions;
