import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { darkTheme } from "../../theme";
import Button from "../../components/button";
import signupLogo from "../../assets/strived-logo-icon.svg";
import { loginUser, verifyLogin } from "../../apis/login/login";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addAccount } from "../../apis/organization/index";
import { clearTokens } from "../../utils/authUtils";
import Cookies from "js-cookie";
import { URL_POSTFIX } from "../../constants/constant";
import SioIcon from "../../components/icons/IconLibrary";
const Otp = (props) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const { email, selectedOrg, selectedOrgUuid } = props;

  const handleLogin = async () => {
    let updatedData = await verifyLogin({
      email: email,
      org_name: selectedOrg,
      org_uuid: selectedOrgUuid,
      otp: otp,
    });
    if (updatedData.status === 200) {
      const decodedToken = jwt_decode(updatedData?.data?.token);
      if (decodedToken !== undefined) {
        const user = decodedToken.data;
        let userDetails = {
          org_uuid: user.org_uuid,
          user_uuid: user.user.user_uuid,
          email: user.user.email,
        };

        const addPayload = {
          email: email,
          org_name: selectedOrg,
          org_uuid: selectedOrgUuid,
          user_uuid: user.user.user_uuid,
        };
        const deviceUuid = Cookies.get("device_uuid");
        if (deviceUuid) {
          addPayload.device_uuid = deviceUuid;
        }
        const addNewacc = await addAccount(addPayload);
        if (addNewacc.status === 200) {
          if (addNewacc?.data?.device_uuid) {
            Cookies.set("device_uuid", addNewacc?.data?.device_uuid, {
              domain: URL_POSTFIX,
              expires: 7,
            });
            Cookies.set("device_id", addNewacc?.data?.id, {
              domain: URL_POSTFIX,
              expires: 7,
            });
          }
        }
        clearTokens();

        Cookies.set("token", updatedData?.data?.token, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("refresh_token", updatedData?.data?.refresh_token, {
          domain: URL_POSTFIX,
          expires: 15,
        });
        Cookies.set("user_email", user.user.email, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("org", selectedOrg, { domain: URL_POSTFIX, expires: 7 });
        Cookies.set("user", JSON.stringify(userDetails), {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("userName", user?.userdetails?.user_data?.first_name, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("org_uuid", userDetails?.org_uuid, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        const userRole = user?.roles ? user?.roles : user?.userdetails?.roles;
        Cookies.set("user_role", userRole, {
          domain: URL_POSTFIX,
          expires: 7,
        });

        if (URL_POSTFIX === "localhost") {
          navigate("/");
        } else {
          window.location.href = `https://${selectedOrg}${URL_POSTFIX}`;
        }
      }
    } else {
      toast.error(updatedData?.data?.message, {
        theme:"light",
        icon: (
          <SioIcon name="close" size={16} color="blue" />
        ),
      });
    }
  };

  const handleEmailContinue = async () => {
    try {
      const loginResp = await loginUser({
        email: email,
        org_name: selectedOrg,
        org_uuid: selectedOrgUuid,
      });
      if (loginResp?.status === 200) {
        toast.success("Resent the OTP", {
          theme:"light",
          icon: (
            <SioIcon name="check" size={16} color="blue" />
          ),
        });
      } else {
        toast.error("Failed to resend the OTP", {
          theme:"light",
          icon: (
            <SioIcon name="close" size={16} color="blue" />
          ),
        });
      }
    } catch (e) {
      toast.error(e?.message,{
        theme:"light",
        icon: (
          <SioIcon name="close" size={16} color="blue" />
        ),
      });
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-dark min-h-screen flex flex-col xs:p-2">
        <div className="sio-col-420 mb-auto">
          <img
            src={signupLogo}
            alt="signup-page-logo"
            className="mt-12 mb-2 xs:h-10"
          />
          <div className="sio-card bg-white-gradient">
            <div className="text-center">
              <h1>Verify Your Account</h1>
              <p className="text-secondary-light text-xs sm:text-sm">
                We have sent a 6-digit code to your email. Please enter it
                below. Can't find it? Check your spam folder.
              </p>
            </div>

            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input
                  {...props}
                  style={{
                    backgroundColor: "inherit",
                  }}
                  className="w-full h-14 text-2xl text-center border border-gray-500 rounded-md bg-black
                  focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-pink-500 text-white"
                />
              )}
              containerStyle="flex space-x-3 mb-6"
            />
            <Button
              variant="secondary"
              onClick={() => {
                handleLogin();
              }}>
              Verify
            </Button>
            <p className="text-secondary-light text-xs sm:text-sm text-center">
              Didn't receive the code?{" "}
              <span
                className="sio-link-secondary hover:cursor-pointer"
                onClick={() => handleEmailContinue()}>
                Resend
              </span>
            </p>
          </div>
        </div>
        <footer className="flex justify-center p-3">
          <ul className="flex divide-x text-sm divide-light font-medium leading-none flex-wrap justify-center">
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/">
                Terms of Use
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/">
                Privacy Policy
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0 text-muted-light xs:border-0 md:border-1">
              ©2024 All Rights Reserved
            </li>
          </ul>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default Otp;
