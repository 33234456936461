import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

const SioTooltipClampedText = ({ text, lines = 2, className }) => {
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const isOverflow =
        textRef.current.scrollHeight > textRef.current.offsetHeight ||
        textRef.current.scrollWidth > textRef.current.offsetWidth;
      setIsOverflowing(isOverflow);
    }
  }, [text]);

  return (
    <Tooltip title={isOverflowing ? text : ""} arrow>
    <p
      ref={textRef}
      className={`line-clamp-${lines} ${className} relative`}
      style={{
        display: "-webkit-box",
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {text}{" "}
      {/* {isOverflowing && (
        <Tooltip title={text} arrow>
          <span className="absolute bottom-0 right-0 rounded-full bg-dark/5 text-dark size-3" style={{ cursor: "pointer" }}>...</span>
        </Tooltip>
      )} */}
    </p>
    </Tooltip>
  );
};

SioTooltipClampedText.propTypes = {
  text: PropTypes.string.isRequired,
  lines: PropTypes.number,
  className: PropTypes.string,
};

export default SioTooltipClampedText;
