import React, { forwardRef, useMemo } from "react";

const Button = forwardRef(
  (
    {
      type = "button",
      onClick,
      variant = "",
      size = "large",
      className = "",
      outline = false,
      fab = false, // New prop to enable FAB styles
      children,
      disabled = false,
      ...props
    },
    ref
  ) => {
    // Variant CSS logic
    const getVariantClass = (variant, outline) => {
      if (outline) {
        switch (variant) {
          case "primary":
            return "btn-outline-primary";
          case "secondary":
            return "btn-outline-secondary";
          case "danger":
            return "btn-outline-danger";
          case "dark":
            return "btn-outline-dark";
          case "light":
            return "btn-outline-light";
          case "default":
            return "btn-outline-default";
          default:
            return ""; // Default outline style
        }
      } else {
        switch (variant) {
          case "primary":
            return "btn-primary";
          case "secondary":
            return "btn-secondary";
          case "danger":
            return "bg-btnDanger-gradient text-white hover:bg-red-700";
          case "dark":
            return "btn-dark";
          case "light":
            return "btn-light";
          case "default":
            return "btn-default";
          default:
            return "";
        }
      }
    };

    // Size CSS logic using useMemo
    const sizeCss = useMemo(() => {
      switch (size) {
        case "xs":
          return "btn-xs"; // Extra small size style
        case "sm":
          return "btn-sm"; // Small size style
        case "md":
          return "w-1/2"; // Medium width style
        case "lg":
          return "btn-lg"; // Large width style
        case "full":
          return "w-full"; // Full width style
        default:
          return ""; // Default to large
      }
    }, [size]);

    // FAB CSS logic
    const fabCss = fab
      ? "soi-btn-fab"
      : "";

    return (
      <button
        ref={ref}
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={`sio-btn ${fabCss} ${getVariantClass(variant, outline)} ${sizeCss} ${className}`}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default Button;
