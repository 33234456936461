/* eslint-disable no-unused-vars */
import { useRef, useMemo, createContext, useEffect, Children } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./Pages/login/login";
import "./App.css";
// import { StrivedContext } from "./context/context";
import { useState } from "react";
import LoadingOverlay from "./components/loadingOverlay/loadingOverlay";
import { Toaster } from "react-hot-toast";
import ModalBackdrop from "./components/ModalBackdrop";
import Signup from "./Pages/signup/signup";
import Layout from "./components/layout/layout";
import LandingPage from "./Pages/landingpage/landingPage";
import ChildRecommendation from "./components/data_ingestion/child-recommendation/index";
import CustomRecommendation from "./components/data_ingestion/custom-recommendation/index";
import DataSource from "./Pages/datasource/data-source";
import FileTypes from "./Pages/datasource/file-types/file-types";
import FileType from "./components/data_ingestion/file-type/index";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";

import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./theme";
import FileUpload from "./components/data_ingestion/fileUpload/fileUpload";
import GreetPage from "./Pages/greetpage/greetPage";
import Suggestions from "./Pages/selectrecommendationspage/suggestions/suggestions";
import SuggestionsSidebar from "./Pages/selectrecommendationspage/suggestions/suggestionsSidebar";
import AskMeanything from "./Pages/askMeAnything/askMeAnything";
import SavedQuestions from "./Pages/selectrecommendationspage/savedQuestions/savedQuestions";
import { getFilesList } from "./apis/recommendation/recommendation";
import ArchivedDataSources from "./Pages/datasource/archived-data-sources-list/archived-data-sources-list";
import SuggestionPage from "./Pages/tempSessionPage/suggestionPage";

export const StrivedContext = createContext();

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [promptHistory, setPromptHistory] = useState([]);
  const [recs, setRecs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [suggestions, setSuggestions] = useState(null);

  const theme = useMemo(
    () => (isDarkMode ? darkTheme : lightTheme),
    [isDarkMode]
  );

  const routesWithoutLayout = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
  ];

  const routesWithLayout = [
    {
      path: "/",
      element: <GreetPage />,
    },
    {
      path: "/landingPage",
      element: <LandingPage />,
    },
    { path: "/suggestion", element: <SuggestionPage /> },
    {
      path: "/customRecommendation",
      element: <CustomRecommendation />,
    },
    {
      path: "/childRecommendation",
      element: <ChildRecommendation />,
    },
    {
      path: "/fileType",
      element: <FileType />,
    },
    {
      path: "/fileUpload",
      element: <FileUpload />,
    },
    {
      path: "/data-source",
      element: <DataSource />,
      children: [
        {
          path: "archived-data-sources", // Relative path
          element: <ArchivedDataSources />,
        },
        {
          path: "file-types",
          element: <FileTypes />,
        },
      ],
    },
    {
      path: "/suggestions",
      element: <Suggestions />,
    },
    {
      path: "/suggestionsSidebar",
      element: <SuggestionsSidebar />,
    },
    { path: "/askmeanything", element: <AskMeanything /> },
    { path: "/savedQuestions", element: <SavedQuestions /> },
  ];

  const modalRef = useRef();

  return (
    <ThemeProvider theme={lightTheme}>
      <GoogleOAuthProvider clientId="115840353103-nrk6b4rs4m72od7shkgq9lreto10k88r.apps.googleusercontent.com">
        <StrivedContext.Provider
          value={{
            suggestions,
            setSuggestions,
          }}
        >
          <div>
            <Router>
              <Routes>
                {routesWithoutLayout.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  ></Route>
                ))}

                {routesWithLayout.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Layout>{route.element}</Layout>}
                  >
                    {route.children &&
                      route.children.map((child, childIndex) => (
                        <Route
                          key={childIndex}
                          path={child.path}
                          element={child.element}
                        />
                      ))}
                  </Route>
                ))}
              </Routes>
            </Router>
          </div>
          {loadingOverlay && <LoadingOverlay />}
          <ModalBackdrop ref={modalRef} />
          <Toaster position="top-right" />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            closeButton={false}
            draggable
            pauseOnHover
            theme="dark"
            className="sio-toaster"
          />
        </StrivedContext.Provider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
