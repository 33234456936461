/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Dialog, Skeleton, Tooltip } from "@mui/material";
import Button from "../../../components/button";
import { toast } from "react-toastify";
import { FiSearch } from "react-icons/fi";
import { debounce } from "lodash";
import {
  bookmarkQuestions,
  deleteSavedQuestions,
} from "../../../apis/recommendation/recommendation";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { devApis } from "../../../constants/constant";
import { getUserNames } from "../../../utils";
import { DataContext } from "../../../components/layout/layout";
import SioIcon from "../../../components/icons/IconLibrary";

const SavedQuestionsSidebar = ({
  recommendationData,
  setSelectedSuggestion,
  setIsPromptEdited,
  selectedSuggestion,
  setEditedPromptData,
  setSavedQuestionsData,
  isModileSidebarActive,
  setModileSidebarIsActive,
}) => {
  const [savedQuestions, setSavedQuestions] = useState(null);
  const [filteredPrompts, setFilteredPrompts] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(
    Array(filteredPrompts?.length).fill(false)
  );
  const [openRemoveModal, setRemoveModalOpen] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deletedQuestionContent, setDeletedQuestionContent] = useState(null);
  const [editPrompt, setEditPrompt] = useState("");

  const { setUpdatedQuestionsData } = useContext(DataContext);

  useEffect(() => {
    if (recommendationData) {
      setSavedQuestions(recommendationData);
      setFilteredPrompts(recommendationData);
      handleElementClick(recommendationData[0]?.id);
      setSelectedSuggestion(recommendationData[0]);
      setEditedPromptData(null);
    }
  }, [recommendationData]);

  useEffect(() => {
    if (recommendationData.length > 0)
      setSelectAll(selectedIds.length === recommendationData.length);
  }, [selectedIds, recommendationData]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleElementClick = (ind) => {
    setSelectedIndex(ind);
  };

  const computeDateTime = (updated) => {
    let hours = updated.getHours();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();
    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${updatedMonth} ${updatedDate}, ${computeDateTime(
          updated
        )}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const handleEditClick = (ind) => {
    const newEditingState = Array(savedQuestions?.length).fill(false);
    newEditingState[ind] = true;
    setIsEditing(newEditingState);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      setSelectedIds(recommendationData.map((item) => item.id));
    }
    setSelectAll(!selectAll);
  };

  const handlePromptHistorySearch = debounce((value) => {
    setFilteredPrompts(() => {
      const data = recommendationData.filter((item) =>
        item.content.toLowerCase().includes(value.toLowerCase())
      );
      return data;
    });
  }, 300);

  const handleRecommendationsChange = (event) => {
    const value = event.target.value;
    handlePromptHistorySearch(value);
  };

  const handleSearch = (e) => {
    handleRecommendationsChange(e);
  };

  const handleRemoveModalClickOpen = (item) => {
    setDeletedQuestionContent(item);
    setRemoveModalOpen(true);
  };

  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleRemoveQuestion = () => {
    try {
      deleteSavedQuestions(deletedQuestionContent.id);
      toast.success("Question has been deleted successfully", {
        icon: (
          <SioIcon name="check" size={16} color="blue" />
        ),
      });
      handleRemoveModalClose();
      //   deleteQuestions(deletedQuestionContent);
      setFilteredPrompts((prevData) => {
        return prevData.filter((item) => item.id !== deletedQuestionContent.id);
      });
    } catch (err) {
      toast.error("Error:", err.message, {
        icon: (
          <SioIcon name="close" size={16} color="blue" />
        ),
      });
    }
  };

  const editMessage = async (user_id, message_id, prompt, filename) => {
    try {
      const token = await checkAndRefreshToken();
      const res = await fetch(`${devApis.PYTHON_BASE_URL}/edit_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_id: message_id,
          new_content: prompt,
          user_id: user_id,
          filename: filename,
        }),
      });

      if (res.status === 200 || res.status === 201) {
        const data = await res.json();
        setIsPromptEdited(false);
        return data;
      }
    } catch (error) {
      toast.error("Error Editing prompt:", error.message, {
        icon: (
          <SioIcon name="close" size={16} color="blue" />
        ),
      });
    }
  };

  const handleEditInputChange = debounce((value) => {
    setEditPrompt(value.innerHTML);
  }, 500);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  return (
    <div className={`flex flex-col border-r sio-sr-left-bar ${
      isModileSidebarActive ? "active" : ""
    }`}>
      <div className="flex items-center p-4 pt-4 md:pt-7 pb-4 relative z-10 bg-white">
        <Button
          outline
          fab
          variant="dark"
          className="flex-shrink-0 size-8 lg:size-10 visible lg:hidden"
          onClick={handleModileSidebarToggle}
        >
          <SioIcon name="leftArrow" size={14}/>
        </Button>
        <Checkbox
          checked={selectAll}
          onChange={() => {
            try {
              handleSelectAllChange();
            } catch (err) {
              toast.error("Failed to select all recommendations", {
                icon: (
                  <SioIcon name="close" size={16} color="blue" />
                ),
              });
            }
          }}
        />
        <div className="flex gap-2 items-center border rounded-full h-10 w-full px-3 me-2">
          <FiSearch size={18} className="text-xl text-grayish" />
          <input
            className="flex-grow px-3 py-2 pl-0 text-sm focus:outline-none focus:border-none"
            placeholder="Search Suggestions..."
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
      <div
        className={`overflow-y-scroll scrollbar-none sio-checkbox-list pt-1 p-5`}
      >
        {savedQuestions && savedQuestions.length === 0
          ? Array.from({ length: 7 }).map((_, ind) => (
              <div
                key={ind}
                className={`sio-checkbox-list-item group/sio-checkbox-list-item `}
              >
                <Skeleton width={"100%"} />
                <Skeleton width={"100%"} />
                <Skeleton width={"40%"} />
              </div>
            ))
          : savedQuestions &&
            filteredPrompts?.map((value, ind) => (
              <React.Fragment key={ind}>
                <div
                  className={`sio-checkbox-list-item group/sio-checkbox-list-item ${
                    selectedIndex === value.id
                      ? "sio-checkbox-list-item-active"
                      : ""
                  } ${isEditing[ind] ? "" : "cursor-pointer"}`}
                  onClick={() => {
                    setSelectedSuggestion(value);
                    handleModileSidebarToggle();
                    setEditedPromptData(null);
                  }}
                >
                  <div
                    className="flex -ml-2 items-start"
                    onClick={() => handleElementClick(value.id)}
                  >
                    <div className="flex-shrink-0 flex-grow-0 w-auto -mt-1 me-1">
                      <Checkbox
                        className="self-start"
                        checked={selectedIds.includes(value.id) || false}
                        onChange={() => handleCheckboxChange(value.id)}
                      />
                    </div>

                    <div className="flex-1">
                      <p
                        contentEditable={`${isEditing[ind]}`}
                        suppressContentEditableWarning={true}
                        className={`${
                          isEditing[ind] ? "" : "line-clamp-2"
                        } text-sm outline-none text-secondary-dark`}
                        onKeyDown={(e) => handleEditInputChange(e.target)}
                      >
                        {value?.content}
                      </p>
                      {!isEditing[ind] && (
                        <p className="text-[11px] text-muted-dark">
                          Saved on
                          <strong>
                            {" "}
                            {value?.last_updated
                              ? calculateTime(value?.last_updated)
                              : calculateTime(value?.created_at)}
                          </strong>
                        </p>
                      )}
                    </div>
                    <div
                      className={`flex-shrink-0 flex-grow-0 w-auto flex items-center relative ${
                        selectedIndex !== value.id &&
                        "transition-all group-hover/sio-checkbox-list-item:mr-0 -mr-8"
                      } ${isEditing[ind] ? "mr-0" : "-mr-8"}`}
                    >
                      {isEditing[ind] && (
                        <Button
                          variant="secondary"
                          className="soi-btn-fab"
                          onClick={async () => {
                            setIsEditing(
                              Array(filteredPrompts?.length).fill(false)
                            );
                            setIsPromptEdited(true);
                            const { data } = await getUserNames();
                            const response = await editMessage(
                              data?.user?.id,
                              selectedSuggestion?.id,
                              editPrompt,
                              selectedSuggestion?.filename
                            );
                            setEditedPromptData(response);
                            await bookmarkQuestions(response?.message_id);
                            setSavedQuestionsData({
                              content: editPrompt,
                              created_at: value?.created_at,
                            });
                            setFilteredPrompts((prevData) =>
                              prevData.map((item) =>
                                item.id === value.id
                                  ? {
                                      ...item,
                                      content: editPrompt,
                                      id: response?.message_id,
                                    }
                                  : item
                              )
                            );
                            setUpdatedQuestionsData(true);
                          }}
                        >
                            <SioIcon name="saveCheck" size={16}/>
                        </Button>
                      )}
                      {!isEditing[ind] && (
                        <>
                          <Tooltip title="Edit">
                            <button
                              className="soi-btn soi-btn-fab soi-btn-flat btn-dark"
                              onClick={(e) => {
                                handleEditClick(ind);
                              }}
                            >
                              <SioIcon name="edit" size={16}/>
                            </button>
                          </Tooltip>
                          <Tooltip title="Remove">
                            <button
                              onClick={(e) => {
                                handleRemoveModalClickOpen(value);
                                e.stopPropagation();
                              }}
                              className={`soi-btn soi-btn-fab invisible soi-btn-flat btn-dark group-hover/sio-checkbox-list-item:${
                                selectedIndex === value.id
                                  ? "invisible"
                                  : "visible"
                              }`}
                            >
                              <SioIcon name="delete" size={16}/>
                            </button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
      </div>
      <Dialog
        open={openRemoveModal}
        onClose={() => handleRemoveModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="max-w-md flex justify-center items-center text-center p-8 flex-col">
          <SioIcon name="delete" size={80} className="mb-5"/>
          <h1 className="text-primary-dark text-xl mb-7">
            Are you sure you want to remove saved question?
          </h1>
          <div className="gap-4 flex">
            <Button
              variant="secondary"
              onClick={() => {
                handleRemoveQuestion();
                setUpdatedQuestionsData(true);
              }}
              size={"sm"}
            >
              Yes, Remove
            </Button>
            <Button
              size={"sm"}
              onClick={() => handleRemoveModalClose()}
              className="text-black"
            >
              Not Now
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SavedQuestionsSidebar;
