import React, { memo } from 'react';
import { IconRegistry } from './iconRegistry';

const SioIcon = memo(({ name, size = 24, color = 'currentColor', className = '' }) => {
  const icon = IconRegistry[name];

  if (!icon) {
    console.warn(`Icon "${name}" does not exist in IconRegistry.`);
    return null;
  }

  return React.cloneElement(icon, {
    width: size,
    height: size,
    fill: color,
    className,
  });
});

export default SioIcon;
