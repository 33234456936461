/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Checkbox, Skeleton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FiSearch } from "react-icons/fi";
import Button from "../../../components/button";
import { debounce } from "lodash";
import SioIcon from "../../../components/icons/IconLibrary";

const SuggestionsSidebar = ({
  recommendationData,
  setSelectedSuggestion,
  isModileSidebarActive,
  setModileSidebarIsActive,
  recommendationId,
}) => {
  const [filteredRec, setFilteredRec] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const params = new URLSearchParams(window.location.search);
  const pathId = Number(params.get("recommendationId"));

  const userLoginDate = new Date();

  const formatDate = (dateStr) => {
    const today = new Date();
    const targetDate = new Date(dateStr);

    const isToday = today.toDateString() === targetDate.toDateString();
    const isYesterday =
      new Date(today.setDate(today.getDate() - 1)).toDateString() ===
      targetDate.toDateString();

    const isSameMonth =
      userLoginDate.getMonth() === targetDate.getMonth() &&
      userLoginDate.getFullYear() === targetDate.getFullYear();

    const isSameYear = userLoginDate.getFullYear() === targetDate.getFullYear();

    if (isToday) return "Today";
    if (isYesterday) return "Yesterday";
    if (isSameMonth) return "This Month";
    if (isSameYear) {
      return targetDate.toLocaleDateString("en-US", { month: "long" });
    }

    return targetDate.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });
  };

  const groupByFormattedDate = (data) => {
    if (!data) return null;

    const sortedData = data.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    return sortedData.reduce((acc, item) => {
      const dateKey = formatDate(item.created_at);

      const existingGroup = acc.find((group) => group.date === dateKey);
      if (existingGroup) {
        existingGroup.content.push(item);
      } else {
        acc.push({ date: dateKey, content: [item] });
      }
      return acc;
    }, []);
  };

  const handleElementClick = (id) => {
    setSelectedIndex(id);
    const newUrl = `${window.location.pathname}?recommendationId=${id}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      setSelectedIds(recommendationData.map((item) => item.id));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (recommendationData.length > 0)
      setSelectAll(selectedIds.length === recommendationData.length);
  }, [selectedIds, recommendationData]);

  useEffect(() => {
    if (recommendationData.length > 0) {
      setFilteredRec(recommendationData);
      if (recommendationId) {
        const newUrl = `${window.location.pathname}?recommendationId=${recommendationId}`;
        window.history.pushState({ path: newUrl }, "", newUrl);
        setSelectedIndex(pathId);
      } else {
        const newUrl = `${window.location.pathname}?recommendationId=${
          pathId === 0 ? recommendationData[0].id : pathId
        }`;
        window.history.pushState({ path: newUrl }, "", newUrl);
        setSelectedIndex(pathId === 0 ? recommendationData[0].id : pathId);
      }
    }
  }, [recommendationData, recommendationId]);

  const computeDateTime = (updated) => {
    let hours = updated.getHours();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();
    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${updatedMonth} ${updatedDate}, ${computeDateTime(
          updated
        )}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const handlePromptHistorySearch = debounce((value) => {
    setFilteredRec(() => {
      const data = recommendationData.filter((item) =>
        item.content.toLowerCase().includes(value.toLowerCase())
      );
      return data;
    });
  }, 300);

  const handleRecommendationsChange = (event) => {
    const value = event.target.value;
    handlePromptHistorySearch(value);
  };

  const handleSearch = (e) => {
    handleRecommendationsChange(e);
  };

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  return (
    <div
      className={`flex flex-col border-r sio-sr-left-bar ${
        isModileSidebarActive ? "active" : ""
      }`}
    >
      <div className="flex items-center p-4 pt-4 md:pt-7 pb-4 relative z-10 bg-white">
        <Button
          outline
          fab
          variant="dark"
          className="flex-shrink-0 size-8 lg:size-10 visible lg:hidden"
          onClick={handleModileSidebarToggle}
        >
          <SioIcon name="leftArrow" size={14} />
        </Button>
        <Checkbox
          checked={selectAll}
          onChange={() => {
            try {
              handleSelectAllChange();
            } catch (err) {
              toast.error("Failed to select all recommendations", {
                icon: <SioIcon name="close" size={16} color="blue" />,
              });
            }
          }}
        />
        <div className="flex gap-2 items-center border rounded-full h-10 w-full px-3 me-2">
          <FiSearch size={18} className="text-xl text-grayish" />
          <input
            className="flex-grow px-3 py-2 pl-0 text-sm focus:outline-none focus:border-none"
            placeholder="Search Suggestions..."
            onChange={(e) => handleSearch(e)}
          />
        </div>
        {/* <Tooltip title="Sort Suggestions">
          <Button
            outline
            fab
            variant="dark"
            className="flex-shrink-0 size-8 lg:size-10"
            onClick={() => null}
          >
            <SioIcon name="sorting" size={18} />
          </Button>
        </Tooltip> */}
      </div>

      <div className="overflow-y-scroll scrollbar-none sio-checkbox-list -mt-4 pt-0 p-5">
        {recommendationData &&
          (recommendationData.length === 0
            ? Array.from({ length: 7 }).map((_, ind) => (
                <div
                  key={ind}
                  className={`sio-checkbox-list-item group/sio-checkbox-list-item `}
                >
                  <Skeleton width={"100%"} />
                  <Skeleton width={"100%"} />
                  <Skeleton width={"40%"} />
                </div>
              ))
            : recommendationData &&
              groupByFormattedDate(filteredRec).map((groupedItems, ind) => (
                <div key={ind}>
                  {groupedItems?.date !== "Invalid Date" && (
                    <p className="font-montserrat text-xs font-semibold text-secondary-dark p-4 pb-1">
                      {groupedItems.date}{" "}
                      <span className="text-muted-dark font-light">
                        ({groupedItems.content.length} Suggestions)
                      </span>
                    </p>
                  )}
                  {groupedItems?.content?.map(
                    (value, ind) =>
                      value.content && (
                        <div
                          key={ind}
                          className={`sio-checkbox-list-item ${
                            selectedIndex === value.id
                              ? "sio-checkbox-list-item-active"
                              : ""
                          } cursor-pointer`}
                        >
                          <div className="flex -ml-2 items-start">
                            <div className="flex-shrink-0 flex-grow-0 w-auto -mt-1 me-1">
                              <Checkbox
                                className="self-start"
                                checked={
                                  selectedIds.includes(value.id) || false
                                }
                                onChange={() => handleCheckboxChange(value.id)}
                              />
                            </div>
                            <i className="sio-circle-icon bg-dark/10 size-8 shrink-0">
                              <SioIcon name="trend" size={24} />
                            </i>
                            <div
                              className="w-full px-2 overflow-hidden"
                              onClick={() => {
                                setSelectedSuggestion(value);
                                handleModileSidebarToggle();
                                handleElementClick(value.id);
                              }}
                            >
                              <div className="flex gap-2 overflow-hidden">
                                <h6 className="font-semibold text-secondary-900 text-sm truncate">
                                  {value?.category?.replace(
                                    /[^a-zA-Z0-9 ]/g,
                                    ""
                                  ) || ""}
                                </h6>
                              </div>
                              <p className="line-clamp-2 text-sm text-secondary-dark">
                                {value.content.replace(/[^a-zA-Z ]/g, "")}
                              </p>
                            </div>
                            <div className="flex flex-col items-end justify-start">
                              <p className="text-[11px] text-muted34-dark mb-auto whitespace-nowrap text-right">
                                {value?.last_updated
                                  ? calculateTime(value?.last_updated)
                                  : calculateTime(value?.created_at)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )))}
      </div>
    </div>
  );
};

export default SuggestionsSidebar;
