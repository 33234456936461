/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../components/button";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  Drawer,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Skeleton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import InputField from "../../../components/InputField";
import { FiSearch } from "react-icons/fi";
import {
  archiveTheFile,
  deleteFile,
  downloadFile,
  editRecommendation,
  getRecByS3Keys,
  viewFile,
} from "../../../apis/recommendation/recommendation";
import { computeSignature, getUserNames } from "../../../utils";
import { devApis } from "../../../constants/constant";
import { toast } from "react-toastify";
import { StrivedContext } from "../../../App";
// import { useGoogleLogin } from "@react-oauth/google";
import { getFeatureStatus } from "../../../apis/organization/index";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import Cookies from "js-cookie";
import { DataContext } from "../../../components/layout/layout";
import SioIcon from "../../../components/icons/IconLibrary";

const FilesUploaded = ({ dataSource }) => {
  const [dataLabel, setDataLabel] = useState([]);
  const [filteredFileData, setFilteredFileData] = useState(null);
  const [isLabelChanged, setIsLabelChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadBtnEnabled, setIsDownloadBtnEnabled] = useState(false);
  const [isDeleteBtnEnabled, setIsDeleteBtnEnabled] = useState(false);

  const { setSuggestions } = useContext(StrivedContext);
  const { setHighlightField, setS3Key, s3Key, setIsArchivedDataSources } =
    useContext(DataContext);

  useEffect(() => {
    dataSource &&
      setFilteredFileData(dataSource.filter((file) => !file?.archive_status));
  }, [dataSource, isLabelChanged]);

  useEffect(() => {
    if (isLabelChanged || !filteredFileData) return;
    const labels = Array.from(
      new Set(filteredFileData.map((file) => file.file_type?.name))
    ).map((name, index) => ({ name, index }));
    setDataLabel(labels);
    setDataLabelFilter(labels.map((label) => label.name));
  }, [filteredFileData]);

  const computeDateTime = (updated) => {
    let hours = updated.getHours();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();
    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${updatedDate} ${updatedMonth}, ${computeDateTime(
          updated
        )}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  // File Description
  const [openFileDescriptionDrawer, setFileDescriptionDrawerOpen] =
    React.useState(false);
  const toggleFileDescriptionDrawer = (newOpen, file) => () => {
    if (newOpen) {
      getDownloadFeatureStatus();
    }
    setFileDescriptionDrawerOpen(newOpen);
    setSelectedFileData(file);
  };

  // Upload Data File
  const [openUploadDataFileDrawer, setUploadDataFileDrawerOpen] =
    React.useState(false);
  const toggleUploadDataFileDrawer = (newOpen) => () => {
    setUploadDataFileDrawerOpen(newOpen);
  };

  // Upload Data File
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);

  const fileInputRef = useRef(); // Reference to the file input

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    validateFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    validateFile(file);
    setIsDragOver(false);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null); // Clear the selected file
    setErrorMessage(""); // Clear any previous error message
    fileInputRef.current.value = ""; // Clear the file input value
  };

  const validateFile = (file) => {
    if (file) {
      const allowedExtensions = ["xls", "xlsx", "csv", "pdf", "filepart"];
      const maxSize = 10 * 1024 * 1024;
      const fileName = file.name.toLowerCase();
      const fileSize = file.size;

      const fileExtension = fileName.split(".").pop();

      if (!allowedExtensions.includes(fileExtension)) {
        setErrorMessage(
          "Invalid file type. Only xls, xlsx, csv, pdf and filepart files are allowed."
        );
        setSelectedFile(null);
        return;
      }

      if (fileSize > maxSize) {
        setErrorMessage("File size exceeds the 10MB limit.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
      setErrorMessage("");
    }
  };

  const [dataLabelFilter, setDataLabelFilter] = React.useState([]);

  const handleChangeDataLabelFilter = (event) => {
    const selectedLabels = event.target.value;
    const {
      target: { value },
    } = event;

    setDataLabelFilter(typeof value === "string" ? value.split(",") : value);
    let filterByLabel;
    if (selectedLabels.length > 0 && selectedLabels[0]) {
      filterByLabel = selectedLabels.map((lable) =>
        dataSource.filter((file) => file.file_type.name === lable)
      );
    }
    if (selectedLabels.length === 0) {
      filterByLabel = dataSource;
    }
    setIsLabelChanged(true);
    filterByLabel && setFilteredFileData(filterByLabel.flat(Infinity));
  };

  const handleSelectAllDataLabelFilter = (event) => {
    handleChangeDataLabelFilter({
      target: { value: dataLabel.map((label) => label.name) },
    });
    if (event.target.checked) {
      setDataLabelFilter(dataLabel.map((label) => label.name));
    } else {
      setDataLabelFilter([]);
    }
  };

  const isAllSelectedDataLabelFilter =
    dataLabelFilter && dataLabel && dataLabelFilter.length === dataLabel.length;

  const handleClearAllDataLabelFilter = () => {
    setDataLabelFilter([]);
  };

  const [openRemoveModal, setRemoveModalOpen] = React.useState(false);
  const handleRemoveModalClickOpen = (file) => {
    setRemoveModalOpen(true);
    setSelectedFileData(file);
  };
  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleChangeDataLabel = (item, value) => {
    setFilteredFileData(
      filteredFileData.map((file) => {
        if (file.id === item.id) {
          return {
            ...file,
            file_type: {
              ...file.file_type,
              name: value,
            },
          };
        }
        return file;
      })
    );
    const config = {
      associated_files: [item.original_file_name],
      description: item.file_type.description,
      name: item.file_type.name,
    };
    editRecommendation(config, item.file_type.id);
  };

  // Add New Data Label Modal
  const [openAddDataLabelModal, setAddDataLabelModalOpen] =
    React.useState(false);
  const handleAddDataLabelModalClickOpen = () => {
    setAddDataLabelModalOpen(true);
  };
  const handleAddDataLabelModalClose = () => {
    setAddDataLabelModalOpen(false);
  };

  const handleInput = (event) => {
    const { value } = event.target;
    const filteredData = dataSource.filter((data) =>
      data.original_file_name.toLowerCase().includes(value.toLowerCase())
    );
    if (isLabelChanged) setIsLabelChanged(false);
    setFilteredFileData(filteredData);
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  const [selectedFileData, setSelectedFileData] = useState(null);

  const debouncedInputHandler = debounce(handleInput, 500);

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast(`Please select a file to upload`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        className: "custom-toast",
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    const getUUID = await getUserNames();
    const orgUuid = getUUID.data.org_uuid;
    formData.append("org_uuid", orgUuid);
    const secretKey = "wBLm_uRNsyuxHWZrIufExXRBI-uE0aDkjT7xsKLbn70=";
    const fileContent = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result;
        resolve(binaryString);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(selectedFile);
    });
    const fileUploadsignature = computeSignature(
      secretKey,
      "POST",
      "/upload_file",
      {},
      { org_uuid: orgUuid },
      "",
      fileContent
    );
    try {
      const fileUploadResponse = await fetch(
        `${devApis.PYTHON_BASE_URL}/upload_file`,
        {
          method: "POST",
          headers: {
            "x-signature": fileUploadsignature,
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );
      const fileUploadData = await fileUploadResponse.json();
      setFilteredFileData((prevFiles) => [fileUploadData, ...prevFiles]);
      const response = await fetch(
        `${devApis.PYTHON_BASE_URL}/convert_csv_to_table`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );
      await response.json();
      setIsLoading(false);
      setSelectedFile(null);
    } catch (error) {
      toast.error("error:", error.message, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      setIsLoading(false);
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (isChecked) => {
    console.log(selectAll);
    if (!isChecked) {
      setS3Key([]);
      setSuggestions([]);
    } else {
      const allS3Keys = filteredFileData.map((item) => item.s3_key);
      setS3Key(allS3Keys);
      getRecByS3Keys(allS3Keys, filteredFileData, setSuggestions);
    }
    // setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (file) => {
    if (s3Key.includes(file.s3_key)) {
      setS3Key(() => {
        const filteredS3Keys = s3Key.filter((key) => key !== file.s3_key);
        getRecByS3Keys(filteredS3Keys, filteredFileData, setSuggestions);
        return filteredS3Keys;
      });
    } else {
      setS3Key(() => {
        const filteredS3Keys = [...s3Key, file.s3_key];
        getRecByS3Keys(filteredS3Keys, filteredFileData, setSuggestions);
        return filteredS3Keys;
      });
    }
  };

  const getDownloadFeatureStatus = async () => {
    const token = await checkAndRefreshToken();
    const payload = {
      appName: "strived",
      featureName: "Data Sources download",
      org_uuid: Cookies.get("org_uuid"),
    };
    const getDownloadFeatureStatusResp = await getFeatureStatus(payload, token);
    if (getDownloadFeatureStatusResp?.status === 200) {
      const features = getDownloadFeatureStatusResp?.data?.features;

      if (Array.isArray(features) && features.length > 0) {
        const status = features[0]?.status ?? false;
        setIsDownloadBtnEnabled(status);
      } else {
        setIsDownloadBtnEnabled(false);
      }
    } else {
      setIsDownloadBtnEnabled(false);
    }
  };

  const getDeleteFeatureStatus = async () => {
    const token = await checkAndRefreshToken();
    const payload = {
      appName: "strived",
      featureName: "Data Sources Delete",
      org_uuid: Cookies.get("org_uuid"),
    };
    const getDeleteFeatureStatusResp = await getFeatureStatus(payload, token);
    if (getDeleteFeatureStatusResp?.status === 200) {
      const features = getDeleteFeatureStatusResp?.data?.features;

      if (Array.isArray(features) && features.length > 0) {
        const status = features[0]?.status ?? false;
        setIsDeleteBtnEnabled(status);
      } else {
        setIsDeleteBtnEnabled(false);
      }
    } else {
      setIsDeleteBtnEnabled(false);
    }
  };
  useEffect(() => {
    getDeleteFeatureStatus();

    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("DataSource");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // const handleLogin = useGoogleLogin({
  //   onSuccess: async (response) => {
  //     const accessToken = response.access_token;
  //     // return accessToken;
  //     const { s3Key, fileName } = JSON.parse(localStorage.getItem("values"));
  //     viewFile(s3Key, fileName, accessToken);
  //   },
  //   onError: (error) => {
  //     console.error("Login Failed:", error);
  //     return error;
  //   },
  //   scope: "https://www.googleapis.com/auth/drive",
  // });

  useEffect(() => {
    if (s3Key && s3Key.length > 0 && dataSource && dataSource.length > 0)
      setSelectAll(dataSource.length === s3Key.length);
  }, [dataSource, s3Key]);

  const isDesktop = useMediaQuery("(min-width:1023px)");

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  const [open, setOpen] = useState(isDesktop);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="p-3 md:p-6 flex flex-wrap gap-0 lg:gap-5">
        <div className="items-center ms-auto flex flex-grow lg:flex-grow-0 gap-2 md:gap-3 order-0 lg:order-1">
          <Button
            outline
            fab
            variant="dark"
            className="size-8 flex lg:hidden me-auto"
            size={"xs"}
            onClick={handleToggle}
          >
            <SioIcon name="filter" size={18} />
          </Button>
          <Link
            to="archived-data-sources"
            onClick={() => setIsArchivedDataSources(true)}
          >
            <Button
              variant="dark"
              className="sio-btn-link !text-xs md:text-sm p-2 text-primary-dark"
              size={"sm"}
            >
              View Archived Data
            </Button>
          </Link>
          <Button
            variant="secondary"
            className="!text-xs md:text-sm"
            onClick={toggleUploadDataFileDrawer(true)}
            size={"sm"}
          >
            {" "}
            Upload New Data
          </Button>
        </div>
        <Collapse in={open} className="max-lg:w-full">
          <div className="flex flex-wrap gap-3 max-lg:grid max-md:grid-cols-1 max-lg:grid-cols-2 md:gap-5 mt-3 md:mt-5 lg:mt-0">
            <div className="max-lg:w-full lg:min-w-64 xl:min-w-96">
              <FormControl fullWidth size="small">
                <InputField
                  placeholder={`Search...`}
                  css="flex-grow px-3 py-2 text-sm focus:outline-none focus:border-none"
                  icon={<FiSearch />}
                  sx={{
                    "&.Mui-focusVisible": {
                      boxShadow: "none",
                    },
                  }}
                  // onFocus={() => }
                  onChange={debouncedInputHandler}
                />
              </FormControl>
            </div>
            <div className="max-lg:w-full lg:min-w-56 xl:min-w-80">
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={dataLabelFilter}
                  onChange={handleChangeDataLabelFilter}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip-label"
                      notched={false}
                      className="sio-input-rounded-full"
                      placeholder="Data Label"
                    />
                  }
                  MenuProps={{
                    PaperProps: {
                      style: { width: "auto" },
                    },
                    MenuListProps: {
                      className: "sio-menu-check-list",
                    },
                  }}
                  renderValue={(selected) => (
                    <div
                      className="flex items-center text-sm text-secondary-dark"
                      dangerouslySetInnerHTML={{
                        __html:
                          selected.length > 0
                            ? `Data Label<span class="bg-warning/55 ml-1 rounded-full size-4 inline-flex text-xs font-extrabold text-secondary-dark items-center justify-center">${selected.length}</span>`
                            : "Data Label",
                      }}
                    />
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <div className="sio-select-header">
                    <div className="me-auto">
                      <Checkbox
                        checked={isAllSelectedDataLabelFilter}
                        indeterminate={
                          dataLabelFilter.length > 0 &&
                          dataLabelFilter.length < dataLabel.length
                        }
                        onChange={handleSelectAllDataLabelFilter}
                      />
                      <span className="!text-primary-dark text-sm font-semibold">
                        Select All
                      </span>
                    </div>
                    <Link
                      onClick={handleClearAllDataLabelFilter}
                      className="sio-link text-sm font-normal normal-case"
                    >
                      Clear All
                    </Link>
                  </div>
                  {dataLabel &&
                    dataLabel.map((file) => (
                      <MenuItem
                        key={file.name}
                        value={file.name}
                        className="sio-menu-item-align-top"
                      >
                        <Checkbox
                          checked={dataLabelFilter.includes(file.name)}
                        />
                        <ListItemText primary={file.name} />
                      </MenuItem>
                    ))}
                  <div className="sio-select-footer-action -mb-2 sticky bottom-0">
                    <Button
                      variant="secondary"
                      onClick={handleAddDataLabelModalClickOpen}
                      className="sio-btn-icon w-full uppercase rounded-t-none font-semibold text-xs"
                    >
                      <SioIcon name="add" size={16} /> Add New Data Label
                    </Button>
                  </div>
                </Select>
              </FormControl>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="overflow-scroll scrollbar-none">
        <div className="p-3 md:p-6 !pt-0">
          <div className="flex flex-col sio-card-shadow !p-0 sio-table-card-md sio-card-sm">
            <table className="table-auto w-full sio-table sio-table-border text-left border-t">
              <thead className="sio-table-thead sticky -top-[1px]">
                <tr>
                  <th>
                    <Checkbox
                      checked={s3Key?.length === filteredFileData?.length}
                      onChange={(e) => {
                        try {
                          handleSelectAllChange(e.target.checked);
                        } catch (err) {
                          toast.error("Failed to select all recommendations", {
                            icon: (
                              <SioIcon name="close" size={16} color="blue" />
                            ),
                          });
                        }
                      }}
                    />
                  </th>
                  <th>Name & Uploaded Date</th>
                  <th>Data Label</th>
                  <th>File Description</th>
                </tr>
              </thead>
              <tbody className="sio-table-tbody">
                {!filteredFileData ? (
                  <>
                    {Array.from({ length: 7 }).map((_, index) => (
                      <tr key={index}>
                        {Array.from({ length: 4 }).map((_, ind) => (
                          <td key={ind}>
                            {ind === 0 ? (
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "2rem", width: "50%" }}
                              />
                            ) : (
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "2rem" }}
                              />
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  filteredFileData.map((file, fileIndex) => (
                    <tr key={file.id} className="relative">
                      <td className="absolute top-0 right-0 lg:relative">
                        <Checkbox
                          checked={s3Key.includes(file.s3_key) || false}
                          onChange={() => handleCheckboxChange(file)}
                        />
                      </td>
                      <td className="max-sm:w-full md:w-1/2 lg:w-1/4 me-8 md:me-0">
                        <p className="text-primary-dark text-sm font-bold break-all">
                          {file.original_file_name}
                          {file.new === true && (
                            <span className="sio-badge sio-badge-warning text-[10px] rounded ms-2">
                              NEW
                            </span>
                          )}
                        </p>
                        <p className="text-secondary-dark text-[13px] font-normal">
                          Uploaded on {calculateTime(file.created_at)}
                        </p>
                        <ul className="sio-list-divide text-[10px] mt-3 lg:mt-4">
                          <li
                            className="sio-list-item"
                            onClick={() => {
                              localStorage.setItem(
                                "values",
                                JSON.stringify({
                                  s3Key: file.s3_key,
                                  fileName: file.original_file_name,
                                })
                              );
                              viewFile(file?.s3_key, file?.original_file_name);
                            }}
                          >
                            <Link className="sio-link-icon">
                              <SioIcon name="view" size={14} />
                              View
                            </Link>
                          </li>
                          <li className="sio-list-item">
                            <Link
                              className="sio-link-icon"
                              onClick={async () => {
                                try {
                                  await archiveTheFile(file?.s3_key);
                                  toast.success(
                                    "File was successfully archived",
                                    {
                                      icon: (
                                        <SioIcon
                                          name="check"
                                          size={16}
                                          color="blue"
                                        />
                                      ),
                                    }
                                  );
                                  setFilteredFileData((prevFile) =>
                                    prevFile.filter(
                                      (item) => item.s3_key !== file.s3_key
                                    )
                                  );
                                } catch (e) {
                                  toast.error(e.message);
                                }
                              }}
                            >
                              <SioIcon name="archive" size={13} /> Archive
                            </Link>
                          </li>
                          {isDeleteBtnEnabled && (
                            <li className="sio-list-item">
                              <Link
                                className="sio-link-icon text-red-800"
                                onClick={() => {
                                  handleRemoveModalClickOpen(file);
                                }}
                              >
                                <SioIcon name="delete" size={13} />
                                Remove
                              </Link>
                            </li>
                          )}
                        </ul>
                      </td>
                      <td
                        className="max-sm:w-full md:w-1/2 md:!pe-10 lg:!pe-0 lg:w-1/4"
                        data-title="Data Label"
                      >
                        <FormControl fullWidth size="small">
                          {/* <Select
                            value={file.file_type?.name}
                            onChange={(e) => {
                              handleChangeDataLabel(file, e.target.value);
                            }}
                            input={
                              <OutlinedInput
                                id={`select-${file.id}`}
                                notched={false}
                                className="sio-input-border-none sio-input-wrap sio-input-xs"
                                placeholder="Data Label"
                              />
                            }
                            renderValue={(selected) =>
                              Array.isArray(selected)
                                ? selected.join(", ")
                                : selected
                            }
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              PaperProps: {
                                style: { width: "auto" },
                              },
                              MenuListProps: {
                                className: "sio-menu-check-list",
                              },
                            }}
                          >
                            {dataLabel &&
                              file.file_type &&
                              dataLabel.map((dataLabelNames) => (
                                <MenuItem
                                  key={dataLabelNames.name}
                                  value={dataLabelNames.name}
                                  className="sio-menu-item-align-top"
                                >
                                  <Radio
                                    checked={
                                      dataLabelNames.name ===
                                      file.file_type.name
                                    }
                                  />
                                  <ListItemText primary={dataLabelNames.name} />
                                </MenuItem>
                              ))}
                            <div className="sio-select-footer-action -mb-2 sticky bottom-0">
                              <Button
                                variant="secondary"
                                onClick={handleAddDataLabelModalClickOpen}
                                className="sio-btn-icon w-full uppercase rounded-t-none font-semibold text-xs"
                              >
                                <SioIcon name="add" size={16} />
                                Add New Data Label
                              </Button>
                            </div>
                          </Select> */}
                          <Select
                            value={
                              dataLabel?.some(
                                (dataLabelNames) =>
                                  dataLabelNames.name === file.file_type?.name
                              )
                                ? file.file_type?.name
                                : ""
                            }
                            onChange={(e) =>
                              handleChangeDataLabel(file, e.target.value)
                            }
                            input={
                              <OutlinedInput
                                id={`select-${file.id}`}
                                notched={false}
                                className="sio-input-border-none sio-input-wrap sio-input-xs"
                                placeholder="Data Label"
                              />
                            }
                            renderValue={(selected) =>
                              Array.isArray(selected)
                                ? selected.join(", ")
                                : selected || "Select a data label"
                            }
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              PaperProps: { style: { width: "auto" } },
                              MenuListProps: {
                                className: "sio-menu-check-list",
                              },
                            }}
                          >
                            {dataLabel?.map((dataLabelNames) => (
                              <MenuItem
                                key={dataLabelNames.name}
                                value={dataLabelNames.name}
                                className="sio-menu-item-align-top"
                              >
                                <Radio
                                  checked={
                                    dataLabelNames.name === file.file_type?.name
                                  }
                                />
                                <ListItemText primary={dataLabelNames.name} />
                              </MenuItem>
                            ))}
                            <div className="sio-select-footer-action -mb-2 sticky bottom-0">
                              <Button
                                variant="secondary"
                                onClick={handleAddDataLabelModalClickOpen}
                                className="sio-btn-icon w-full uppercase rounded-t-none font-semibold text-xs"
                              >
                                <SioIcon name="add" size={16} />
                                Add New Data Label
                              </Button>
                            </div>
                          </Select>
                        </FormControl>
                      </td>
                      <td data-title="File Description">
                        <p className="text-xs text-secondary-dark line-clamp-3 break-all mb-2">
                          {file.file_type?.description}
                        </p>
                        <Link
                          className="sio-link text-[10px] text-primary-900"
                          onClick={toggleFileDescriptionDrawer(true, file)}
                        >
                          View More
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* File Description Sidebar */}
            <Drawer
              anchor={"right"}
              open={openFileDescriptionDrawer}
              onClose={toggleFileDescriptionDrawer(false)}
            >
              {selectedFileData && (
                <div className="sio-sidebar-right w-[100%] md:w-[500px]">
                  <div className="sio-sidebar-header flex items-start">
                    <div className="flex items-start me-4">
                      <svg
                        className="size-10 me-5 mt-1"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.59836 0H11.3551L16.4375 5.0824V14.844C16.4375 16.0303 15.4678 17 14.2815 17H5.59836C4.41205 17 3.44238 16.0303 3.44238 14.844V2.15598C3.44238 0.969631 4.41201 0 5.59836 0Z"
                          fill="#00733B"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.355 0L16.4374 5.0824H11.903C11.6008 5.0824 11.355 4.8366 11.355 4.53442V0Z"
                          fill="#005C2F"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.03568 7.23828H12.7444C13.0047 7.23828 13.2175 7.45105 13.2175 7.71146V12.0069C13.2175 12.2673 13.0048 12.481 12.7444 12.481H1.03568C0.775299 12.481 0.5625 12.2673 0.5625 12.0069V7.71146C0.5625 7.45105 0.775266 7.23828 1.03568 7.23828Z"
                          fill="#005C2F"
                        />
                        <path
                          d="M3.43106 9.27296V10.4466C3.43106 10.7822 3.70412 11.0553 4.03977 11.0553C4.37539 11.0553 4.64845 10.7822 4.64845 10.4466C4.64845 10.295 4.77134 10.1721 4.92291 10.1721C5.07452 10.1721 5.19737 10.295 5.19737 10.4466C5.19737 11.085 4.67807 11.6043 4.03974 11.6043C3.40141 11.6043 2.88208 11.0849 2.88208 10.4466V9.27296C2.88208 8.63463 3.40141 8.11533 4.03974 8.11533C4.67807 8.11533 5.19737 8.63466 5.19737 9.27296C5.19737 9.42457 5.07448 9.54745 4.92291 9.54745C4.7713 9.54745 4.64845 9.42457 4.64845 9.27296C4.64845 8.93734 4.37539 8.66428 4.03977 8.66428C3.70412 8.66428 3.43106 8.93734 3.43106 9.27296ZM6.68058 8.66428H7.1178C7.29434 8.66428 7.43795 8.80788 7.43795 8.98443C7.43795 9.13603 7.56083 9.25888 7.7124 9.25888C7.86401 9.25888 7.98686 9.136 7.98686 8.98443C7.98686 8.5052 7.59699 8.11533 7.11777 8.11533H6.68055C6.12367 8.11533 5.67058 8.56799 5.67058 9.12431C5.67058 9.61675 5.92359 9.85694 6.13582 9.97175C6.40513 10.1174 6.7448 10.1342 6.97686 10.1342C7.23106 10.1342 7.43788 10.3406 7.43788 10.5943C7.43788 10.8485 7.23106 11.0553 6.97686 11.0553H6.53964C6.36313 11.0553 6.21949 10.9117 6.21949 10.7352C6.21949 10.5836 6.09661 10.4607 5.94504 10.4607C5.79343 10.4607 5.67058 10.5836 5.67058 10.7352C5.67058 11.2144 6.06045 11.6043 6.53967 11.6043H6.97689C7.53377 11.6043 7.98686 11.1512 7.98686 10.5943C7.98686 10.0379 7.53377 9.5853 6.97689 9.5853C6.27654 9.5853 6.21956 9.37649 6.21956 9.12428C6.21956 8.87067 6.42635 8.66428 6.68058 8.66428ZM10.7098 8.12931C10.566 8.0815 10.4106 8.15943 10.3628 8.30326L9.64685 10.459L8.93089 8.30326C8.88311 8.15943 8.72782 8.08157 8.58389 8.12931C8.44002 8.17709 8.36212 8.33245 8.4099 8.47632L9.38637 11.4163C9.42366 11.5285 9.52858 11.6043 9.64685 11.6043C9.76512 11.6043 9.87008 11.5285 9.90733 11.4163L10.8838 8.47632C10.9315 8.33245 10.8537 8.17709 10.7098 8.12931Z"
                          fill="white"
                        />
                      </svg>
                      <div>
                        <h2 className="text-base md:text-xl break-all">
                          {selectedFileData.original_file_name}
                        </h2>
                        <span className="text-secondary-dark text-sm">
                          Uploaded on{" "}
                          <strong>
                            {calculateTime(selectedFileData.created_at)}
                          </strong>
                        </span>
                      </div>
                    </div>
                    <Button
                      className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
                      onClick={toggleFileDescriptionDrawer(false)}
                    >
                      <SioIcon name="closeLight" size={24} />
                    </Button>
                  </div>
                  <div className="sio-sidebar-body h-auto p-4 md:p-6 py-4 md:!py-7">
                    <p className="text-secondary-dark font-medium text-sm md:text-[15px]">
                      {selectedFileData.file_type.description}
                    </p>
                  </div>
                  {isDownloadBtnEnabled && (
                    <div className="sio-sidebar-footer gap-4 flex pt-0">
                      <Button
                        variant="secondary"
                        size={"sm"}
                        onClick={() =>
                          downloadFile(
                            selectedFileData?.s3_key,
                            selectedFileData?.original_file_name
                          )
                        }
                      >
                        Download File
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Drawer>

            {/* Upload Data File Sidebar */}
            <Drawer
              anchor={"right"}
              open={openUploadDataFileDrawer}
              onClose={toggleUploadDataFileDrawer(false)}
            >
              <div className="sio-sidebar-right w-[100%] md:w-[500px]">
                <div className="sio-sidebar-header flex items-center">
                  <h2 className="text-base md:text-xl">Upload Data File</h2>
                  <Button
                    className="soi-btn-fab sio-sidebar-close soi-btn-fab soi-btn-flat btn-lg ms-auto"
                    onClick={toggleUploadDataFileDrawer(false)}
                  >
                    <SioIcon name="closeLight" size={24} />
                  </Button>
                </div>
                {isLoading ? (
                  <div className="flex justify-center items-center text-md font-semibold w-full h-full pt-10">
                    <svg width={0} height={0}>
                      <defs>
                        <linearGradient
                          id="my_gradient"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%"
                        >
                          <stop offset="0%" stopColor="#e01cd5" />
                          <stop offset="100%" stopColor="#1CB5E0" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <CircularProgress
                      sx={{
                        "svg circle": { stroke: "url(#my_gradient)" },
                        size: "20px",
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="sio-sidebar-body h-auto p-4 md:p-6 py-4 md:!py-7">
                      <div
                        className={`sio-upload-container ${
                          isDragOver ? "drag-over" : ""
                        }`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={handleClick}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          accept=".xls,.xlsx,.csv,.pdf,.filepart"
                          style={{ display: "none" }}
                          aria-label="Upload file"
                        />
                        <div className="sio-upload-info">
                          <SioIcon name="upload" className="mb-3" size={34} />
                          <p className="text-secondary-dark text-sm md:text-base">
                            Drag & Drop or Choose File
                          </p>
                          <p className="text-xs md:text-xs text-muted-dark">
                            Max. file size: 12 MB
                          </p>
                        </div>
                        {errorMessage && (
                          <div className="error-message text-red-500">
                            {errorMessage}
                          </div>
                        )}
                      </div>
                      <p className="text-xs text-muted-dark mt-2">
                        Supported Format: xls, xlsx, csv, pdf
                      </p>

                      {selectedFile && (
                        <div className="flex rounded-lg border p-3 mt-6">
                          <div className="flex items-start me-4">
                            <svg
                              className="size-8 me-4 mt-1"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.59836 0H11.3551L16.4375 5.0824V14.844C16.4375 16.0303 15.4678 17 14.2815 17H5.59836C4.41205 17 3.44238 16.0303 3.44238 14.844V2.15598C3.44238 0.969631 4.41201 0 5.59836 0Z"
                                fill="#00733B"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.355 0L16.4374 5.0824H11.903C11.6008 5.0824 11.355 4.8366 11.355 4.53442V0Z"
                                fill="#005C2F"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.03568 7.23828H12.7444C13.0047 7.23828 13.2175 7.45105 13.2175 7.71146V12.0069C13.2175 12.2673 13.0048 12.481 12.7444 12.481H1.03568C0.775299 12.481 0.5625 12.2673 0.5625 12.0069V7.71146C0.5625 7.45105 0.775266 7.23828 1.03568 7.23828Z"
                                fill="#005C2F"
                              />
                              <path
                                d="M3.43106 9.27296V10.4466C3.43106 10.7822 3.70412 11.0553 4.03977 11.0553C4.37539 11.0553 4.64845 10.7822 4.64845 10.4466C4.64845 10.295 4.77134 10.1721 4.92291 10.1721C5.07452 10.1721 5.19737 10.295 5.19737 10.4466C5.19737 11.085 4.67807 11.6043 4.03974 11.6043C3.40141 11.6043 2.88208 11.0849 2.88208 10.4466V9.27296C2.88208 8.63463 3.40141 8.11533 4.03974 8.11533C4.67807 8.11533 5.19737 8.63466 5.19737 9.27296C5.19737 9.42457 5.07448 9.54745 4.92291 9.54745C4.7713 9.54745 4.64845 9.42457 4.64845 9.27296C4.64845 8.93734 4.37539 8.66428 4.03977 8.66428C3.70412 8.66428 3.43106 8.93734 3.43106 9.27296ZM6.68058 8.66428H7.1178C7.29434 8.66428 7.43795 8.80788 7.43795 8.98443C7.43795 9.13603 7.56083 9.25888 7.7124 9.25888C7.86401 9.25888 7.98686 9.136 7.98686 8.98443C7.98686 8.5052 7.59699 8.11533 7.11777 8.11533H6.68055C6.12367 8.11533 5.67058 8.56799 5.67058 9.12431C5.67058 9.61675 5.92359 9.85694 6.13582 9.97175C6.40513 10.1174 6.7448 10.1342 6.97686 10.1342C7.23106 10.1342 7.43788 10.3406 7.43788 10.5943C7.43788 10.8485 7.23106 11.0553 6.97686 11.0553H6.53964C6.36313 11.0553 6.21949 10.9117 6.21949 10.7352C6.21949 10.5836 6.09661 10.4607 5.94504 10.4607C5.79343 10.4607 5.67058 10.5836 5.67058 10.7352C5.67058 11.2144 6.06045 11.6043 6.53967 11.6043H6.97689C7.53377 11.6043 7.98686 11.1512 7.98686 10.5943C7.98686 10.0379 7.53377 9.5853 6.97689 9.5853C6.27654 9.5853 6.21956 9.37649 6.21956 9.12428C6.21956 8.87067 6.42635 8.66428 6.68058 8.66428ZM10.7098 8.12931C10.566 8.0815 10.4106 8.15943 10.3628 8.30326L9.64685 10.459L8.93089 8.30326C8.88311 8.15943 8.72782 8.08157 8.58389 8.12931C8.44002 8.17709 8.36212 8.33245 8.4099 8.47632L9.38637 11.4163C9.42366 11.5285 9.52858 11.6043 9.64685 11.6043C9.76512 11.6043 9.87008 11.5285 9.90733 11.4163L10.8838 8.47632C10.9315 8.33245 10.8537 8.17709 10.7098 8.12931Z"
                                fill="white"
                              />
                            </svg>
                            <div>
                              <h6 className="font-manrope text-sm text-primary-dark mb-0">
                                {selectedFile.name}
                              </h6>
                              <p className="text-muted-dark text-xs">
                                {(selectedFile.size / 1024 / 1024).toFixed(2)}{" "}
                                MB
                              </p>
                            </div>
                          </div>
                          <Button
                            className="soi-btn-fab soi-btn-flat btn-lg ms-auto -me-2 !size-8 md:!size-10"
                            onClick={handleRemoveFile}
                            aria-label="Remove file"
                          >
                            <SioIcon name="closeLight" size={24} />
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="sio-sidebar-footer gap-4 flex pt-0">
                      <Button
                        variant="secondary"
                        size={"sm"}
                        onClick={() => {
                          handleFileUpload();
                        }}
                      >
                        Upload
                      </Button>
                      <Button
                        variant="default"
                        size={"sm"}
                        onClick={toggleUploadDataFileDrawer(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Drawer>

            {/* Add New Data Label Modal */}
            <Dialog
              open={openAddDataLabelModal}
              onClose={handleAddDataLabelModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="sio-modal">
                <div className="sio-modal-header">
                  <h1 className="text-primary-dark text-lg md:text-xl flex items-center">
                    <SioIcon
                      name="add"
                      className="text-secondary-800"
                      size={20}
                    />
                    <span className="ms-2">Add New Data Label</span>
                  </h1>
                </div>
                <div className="sio-modal-body">
                  <TextField
                    placeholder="For eg. “Student Performance Data”"
                    fullWidth
                  />
                </div>
                <div className="sio-modal-footer">
                  <Button
                    variant="secondary"
                    onClick={handleAddDataLabelModalClose}
                    size={"sm"}
                  >
                    Save
                  </Button>
                  <Button
                    variant="default"
                    size={"sm"}
                    onClick={handleAddDataLabelModalClose}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                </div>
              </div>
            </Dialog>
            {/* Add New Data Label Modal */}
            <Dialog
              open={openRemoveModal}
              onClose={handleRemoveModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="sio-modal">
                <div className="sio-modal-body">
                  <div className="flex justify-center items-center text-center flex-col mb-3">
                    <SioIcon
                      name="deleteLight"
                      className="mb-5 size-14 md:size-20"
                      size={80}
                    />
                    <h1 className="text-primary-dark text-lg md:text-xl mb-0">
                      Are you sure you want to remove this file?
                    </h1>
                  </div>
                  <div className="rounded-lg border p-3 mb-6">
                    <div className="flex items-center">
                      <span className="shrink-0">
                        <svg
                          className="size-8 me-3"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.59836 0H11.3551L16.4375 5.0824V14.844C16.4375 16.0303 15.4678 17 14.2815 17H5.59836C4.41205 17 3.44238 16.0303 3.44238 14.844V2.15598C3.44238 0.969631 4.41201 0 5.59836 0Z"
                            fill="#00733B"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.355 0L16.4374 5.0824H11.903C11.6008 5.0824 11.355 4.8366 11.355 4.53442V0Z"
                            fill="#005C2F"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.03568 7.23828H12.7444C13.0047 7.23828 13.2175 7.45105 13.2175 7.71146V12.0069C13.2175 12.2673 13.0048 12.481 12.7444 12.481H1.03568C0.775299 12.481 0.5625 12.2673 0.5625 12.0069V7.71146C0.5625 7.45105 0.775266 7.23828 1.03568 7.23828Z"
                            fill="#005C2F"
                          />
                          <path
                            d="M3.43106 9.27296V10.4466C3.43106 10.7822 3.70412 11.0553 4.03977 11.0553C4.37539 11.0553 4.64845 10.7822 4.64845 10.4466C4.64845 10.295 4.77134 10.1721 4.92291 10.1721C5.07452 10.1721 5.19737 10.295 5.19737 10.4466C5.19737 11.085 4.67807 11.6043 4.03974 11.6043C3.40141 11.6043 2.88208 11.0849 2.88208 10.4466V9.27296C2.88208 8.63463 3.40141 8.11533 4.03974 8.11533C4.67807 8.11533 5.19737 8.63466 5.19737 9.27296C5.19737 9.42457 5.07448 9.54745 4.92291 9.54745C4.7713 9.54745 4.64845 9.42457 4.64845 9.27296C4.64845 8.93734 4.37539 8.66428 4.03977 8.66428C3.70412 8.66428 3.43106 8.93734 3.43106 9.27296ZM6.68058 8.66428H7.1178C7.29434 8.66428 7.43795 8.80788 7.43795 8.98443C7.43795 9.13603 7.56083 9.25888 7.7124 9.25888C7.86401 9.25888 7.98686 9.136 7.98686 8.98443C7.98686 8.5052 7.59699 8.11533 7.11777 8.11533H6.68055C6.12367 8.11533 5.67058 8.56799 5.67058 9.12431C5.67058 9.61675 5.92359 9.85694 6.13582 9.97175C6.40513 10.1174 6.7448 10.1342 6.97686 10.1342C7.23106 10.1342 7.43788 10.3406 7.43788 10.5943C7.43788 10.8485 7.23106 11.0553 6.97686 11.0553H6.53964C6.36313 11.0553 6.21949 10.9117 6.21949 10.7352C6.21949 10.5836 6.09661 10.4607 5.94504 10.4607C5.79343 10.4607 5.67058 10.5836 5.67058 10.7352C5.67058 11.2144 6.06045 11.6043 6.53967 11.6043H6.97689C7.53377 11.6043 7.98686 11.1512 7.98686 10.5943C7.98686 10.0379 7.53377 9.5853 6.97689 9.5853C6.27654 9.5853 6.21956 9.37649 6.21956 9.12428C6.21956 8.87067 6.42635 8.66428 6.68058 8.66428ZM10.7098 8.12931C10.566 8.0815 10.4106 8.15943 10.3628 8.30326L9.64685 10.459L8.93089 8.30326C8.88311 8.15943 8.72782 8.08157 8.58389 8.12931C8.44002 8.17709 8.36212 8.33245 8.4099 8.47632L9.38637 11.4163C9.42366 11.5285 9.52858 11.6043 9.64685 11.6043C9.76512 11.6043 9.87008 11.5285 9.90733 11.4163L10.8838 8.47632C10.9315 8.33245 10.8537 8.17709 10.7098 8.12931Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <div className="grow overflow-hidden">
                        <h6 className="font-manrope text-sm text-primary-dark mb-0 flex">
                          <span className="truncate grow">
                            {selectedFileData?.original_file_name}
                          </span>{" "}
                          <span className="shrink-0">.csv</span>
                        </h6>
                        <p className="text-muted-dark text-xs">
                          {/* {(selectedFileData.size / 1024 / 1024).toFixed(2)} MB */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sio-modal-footer justify-center">
                  <Button
                    variant="secondary"
                    onClick={async () => {
                      handleRemoveModalClose();
                      setFilteredFileData((prevArray) => {
                        return [
                          ...prevArray.filter(
                            (file) => file.s3_key !== selectedFileData.s3_key
                          ),
                        ];
                      });
                      await deleteFile([selectedFileData.s3_key]);
                    }}
                    size={"sm"}
                  >
                    Yes, Remove
                  </Button>
                  <Button
                    variant="default"
                    size={"sm"}
                    onClick={handleRemoveModalClose}
                  >
                    Not Now
                  </Button>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilesUploaded;
