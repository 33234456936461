export const FileTypeIcons = {
  trend: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_50_4811)">
        <path
          d="M17.0586 6.17893C17.0576 6.17832 17.0567 6.17771 17.0558 6.1771C16.731 5.96428 16.2951 6.05504 16.0823 6.37984C15.8694 6.70465 15.9602 7.1405 16.285 7.35337C16.6839 7.61475 16.922 8.05492 16.922 8.53085C16.922 9.30628 16.2912 9.93714 15.5157 9.93714C14.7403 9.93714 14.1094 9.30628 14.1094 8.53085C14.1094 8.36369 14.1447 8.10339 14.1856 7.80202C14.4583 5.79164 14.7516 2.67052 10.9853 0.11853C10.4897 -0.197838 9.89057 0.160765 9.89057 0.702514C9.89057 0.703639 9.89053 0.704764 9.89053 0.705889V0.706311C9.88926 3.94776 8.26804 5.57324 6.55162 7.29407C4.71164 9.13879 2.85913 11.0351 2.85913 14.7086V14.8592C2.85913 19.8994 6.95973 24 12 24C17.0403 24 21.1409 19.8994 21.1409 14.8592C21.1409 10.4338 19.0326 7.47407 17.0586 6.17893ZM12 22.5937C7.73515 22.5937 4.26542 19.124 4.26542 14.8592V14.7086C4.26542 11.5775 5.85954 9.97924 7.54732 8.28719C9.45884 6.37075 10.8305 4.8017 11.2053 2.07121C13.2821 3.99796 12.9923 6.13698 12.7921 7.61302C12.7444 7.96487 12.7032 8.26877 12.7032 8.5309C12.7032 10.0818 13.9649 11.3435 15.5158 11.3435C16.7859 11.3435 17.8621 10.4972 18.2101 9.33895C19.0885 10.6806 19.7346 12.5302 19.7346 14.8592C19.7346 19.1241 16.2649 22.5937 12 22.5937Z"
          fill="#00996B"
        />
        <path
          d="M6.37478 4.26509C6.7631 4.26509 7.07792 3.95027 7.07792 3.56195V2.15566C7.07792 1.76734 6.7631 1.45251 6.37478 1.45251C5.98645 1.45251 5.67163 1.76734 5.67163 2.15566V3.56195C5.67163 3.95032 5.98645 4.26509 6.37478 4.26509Z"
          fill="#00996B"
        />
        <path
          d="M16.2188 4.26509C16.6071 4.26509 16.9219 3.95027 16.9219 3.56195V2.15566C16.9219 1.76734 16.6071 1.45251 16.2188 1.45251C15.8304 1.45251 15.5156 1.76734 15.5156 2.15566V3.56195C15.5156 3.95032 15.8304 4.26509 16.2188 4.26509Z"
          fill="#00996B"
        />
        <path
          d="M16.2851 13H13.2223C12.8274 13 12.5074 13.3607 12.5074 13.8056C12.5072 14.2507 12.8274 14.6117 13.2221 14.6117H14.5593L11.5051 18.0544L9.84485 16.1829C9.56578 15.8683 9.11302 15.8683 8.83396 16.1829L7.20946 18.0139C6.93021 18.3287 6.93021 18.8389 7.20928 19.1536C7.48853 19.4684 7.9411 19.4682 8.22035 19.1536L9.3394 17.8924L10.9995 19.7639C11.1335 19.915 11.3154 20 11.5051 20C11.6946 20 11.8765 19.915 12.0105 19.7639L15.5702 15.7514V17.2584C15.5702 17.7033 15.8903 18.0643 16.2851 18.0643C16.68 18.0643 17 17.7033 17 17.2584V13.8059C17 13.3607 16.68 13 16.2851 13Z"
          fill="#99005C"
        />
      </g>
      <defs>
        <clipPath id="clip0_50_4811">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};