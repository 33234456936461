/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  getFilesList,
  getRecByS3Keys,
} from "../../../apis/recommendation/recommendation";
import Button from "../../../components/button";
import SuggestionsSidebar from "./suggestionsSidebar";
import SuggestionsContainer from "./suggestionsContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { StrivedContext } from "../../../App";
import { DataContext } from "../../../components/layout/layout";
import SioIcon from "../../../components/icons/IconLibrary";

const Suggestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredRec, setFilteredRec] = useState([]);
  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);

  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const pathId = Number(params.get("recommendationId"));

  const { recommendationId } = location.state || {};

  const navigate = useNavigate();

  const { suggestions } = useContext(StrivedContext);
  const { setHighlightField } = useContext(DataContext);

  const getRecommendations = async (data) => {
    let recommendation;

    if (data) recommendation = data;

    if (!data) {
      const files = await getFilesList();
      const filteredFileData = files.filter(
        (file) =>
          file.s3_key.includes(".csv") ||
          file.s3_key.includes(".json") ||
          file.s3_key.includes(".csv.filepart")
      );

      const s3_keys =
        JSON.parse(sessionStorage.getItem("s3_keys")).length > 0
          ? JSON.parse(sessionStorage.getItem("s3_keys"))
          : filteredFileData.map((file) => file.s3_key);

      const data1 = await getRecByS3Keys(s3_keys, filteredFileData);
      recommendation = data1;
    }

    if (!recommendation?.length) return;

    setFilteredRec(
      recommendation.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      })
    );

    if (pathId) {
      setSelectedSuggestion(recommendation.find((item) => item.id === pathId));
    } else {
      setSelectedSuggestion(
        recommendation.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })[0]
      );
    }
  };

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("Suggestions");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    setHighlightField("Suggestions");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    getRecommendations(suggestions);
  }, [suggestions]);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    setHighlightField("Ask Anything");
    navigate("/");
  };

  const playGroundTopBar = () => {
    return (
      <div className="flex items-center shadow-md p-3 md:p-5 relative z-10">
        <Button
          outline
          fab
          variant="dark"
          className="me-2 size-8 flex lg:hidden"
          size={"xs"}
          onClick={handleModileSidebarToggle}
        >
          <SioIcon name="filter" size={24} />
        </Button>
        <h1 className="font-normal text-lg md:text-xl text-primary-dark">
          Suggestions
        </h1>
        <div className="items-center ms-auto flex">
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}
          >
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className={`h-full w-full flex flex-col overflow-hidden`}>
        {playGroundTopBar()}

        <div className={`flex flex-row overflow-hidden h-full`}>
          <SuggestionsSidebar
            isModileSidebarActive={isModileSidebarActive}
            recommendationData={filteredRec}
            setModileSidebarIsActive={setModileSidebarIsActive}
            setSelectedSuggestion={setSelectedSuggestion}
            recommendationId={recommendationId}
          />
          <SuggestionsContainer recommendationData={selectedSuggestion} />
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
